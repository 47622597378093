// @ts-check

/**
 * 
 * @param {*} url 
 * @returns {Promise<ArrayBuffer>}
 */
export async function fileDownload(url) {
    console.log(url)
    const request = new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'arraybuffer';
        xhr.send();
        xhr.onload = function (e) {
            if (xhr.status != 200) {
                // onLoad();
                reject(new Error(`Failed to download file: ${xhr.status}`));
            }
            resolve(xhr.response);
        };
        xhr.onerror = (e) => {
            // vid.src = null;
            reject(new Error(`Failed to download file: ${xhr.status}`));
        };
    })
    return await request
}