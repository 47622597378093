// @ts-check

/* context

https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Migrating_from_webkitAudioContext
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Web_audio_spatialization_basics
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Best_practices

https://developer.chrome.com/blog/autoplay/#webaudio

*/

import { NumberEditableProperty } from "../../view/propertyEditor";
import { StackLayout } from "../../view/stackLayout";
import { Treeview } from '../../view/treeview.js';
import { ConnectedStems } from "./ConnectedStems";
import IWebAudio from "./interfaces/IAudioPlayer.js";
import { PlaylistManager } from "./playlistAPI/playlistManager.js";
import { getRadioSettings } from "./playlistAPI/Radio.js";

/**
 * @implements {IWebAudio}
 */
export class WebAudio {
  /**
   * @type {AudioContext}
   */
  audioContext;
  /**
* 
* @type {import('../../customerAccount.js').CustomerAccount}
*/
  account;
  /**
* 
* @type {import('../../resources.js').Resources}
*/
  resources;
  /**
 * @type {boolean}
 */
  isWaitingForGesture;
  /**
 * @type {import('./ConnectedStems.js').ConnectedStems}
 */
  connectedStems;
  /**
* @type {string}
*/
  static ambiancelayerName = "ambiance";
  /**
* @type {string}
*/
  static musiclayerName = "music";
  /**
* @type {string}
*/
  static effectslayerName = "effects";
  /**
* @type {Array.<string>}
*/
  static layerNames = [WebAudio.ambiancelayerName, WebAudio.musiclayerName, WebAudio.effectslayerName];
  /**
 * @type {Map.<string,GainNode>}
 */
  layers = new Map();
  /**
* @type {GainNode}
*/
  volumeNode;
  /** 
   * stores a reference to the playlist manager object
   * @type {PlaylistManager} 
   */
  playlistManager

  /**
   * @type {import("../visual/MediaSourcePlayerComponent.js").actionFunction}
   */
  onStartAudioOnGesture;

  /**
   * 
   * @param {import('../../resources.js').Resources} resources 
   * @param {import('../../customerAccount.js').CustomerAccount} account 
   */
  constructor(resources, account) {
    this.resources = resources;
    this.account = account;

    this.connectedStems = new ConnectedStems(this);
    // this.playlistManager = new PlaylistManager("playlists", getRadioSettings)
  }
  /**
   * 
   * @param {string} layer 
   * @param {GainNode} node 
   */
  connectNodeToLayer(layer, node) {
    let layerNode = this.layers.get(layer);
    if (layerNode) {
      node.connect(layerNode);
    }
    else {
      node.connect(this.volumeNode);
    }
  }
  /**
   * 
   */
  initialize() {
    this.tryStartAudio();
  }
  /**
   * 
   * @returns {boolean}
   */
  tryStartAudio() {
    if (this.audioContext != undefined) {
      return true;
    }
    try {
      this.audioContext = new AudioContext();
      this.isWaitingForGesture = this.audioContext.state === "suspended";

      this.volumeNode = this.audioContext.createGain();
      this.volumeNode.connect(this.audioContext.destination);

      WebAudio.layerNames.forEach((layer) => {
        this.layers.set(layer, this.audioContext.createGain());
        this.layers.get(layer)?.connect(this.volumeNode);
      });
      return true;
    } catch (e) { }
    return false;
  }
  /**
   * 
   */
  uninitialize() { }
  /**
   * 
   */
  deactivate() {
    this.isDeactivate = true;
    this.audioContext.suspend();
    //this.archiveVolume=this.volumeNode.gain.value;
    // this.volumeNode.gain.value=0;
    //this.volumeNode.disconnect();
  }
  /**
   * 
   */
  reactivate() {
    if (this.isDeactivate) {
      this.isDeactivate = false;

      this.audioContext.resume();
      // this.volumeNode.gain.value=this.archiveVolume;
      //this.volumeNode.connect(this.audioContext.destination);
    }
  }
  /**
   * 
   * @returns {string}
   */
  storageItemName() {
    return this.resources.combineJsonResourceName(this.account.application.name, this.account.name, "audio.storage");
  }
  /**
   * 
   */
  saveState() { }
  /**
   * 
   */
  shutdown() {
    this.saveState();
  }
  /**
   * 
   * @param {Array.<import('./stem.js').Stem>} stems 
   */
  playSoundEffects(stems) {
    stems.forEach(element => {
      this.connectedStems.playSoundEffect(element);
    });
  }
  /**
   * 
   */
  startAudioOnGesture() {
    this.tryStartAudio();

    if (this.isWaitingForGesture) {
      this.audioContext.resume();
      this.isWaitingForGesture = false;
      if (this.onStartAudioOnGesture) {
        this.onStartAudioOnGesture();
      }

      console.log("AudioContext resume");
      this.connectedStems.startAudioOnGesture();

    }
  }

  /**
   * 
   * @param {GainNode} node 
   * @param {string} name 
   * @returns {NumberEditableProperty}
   */
  static createEditablePropertyForGainNode(node, name) {
    let prop = new NumberEditableProperty();
    prop.name = name;
    prop.setValue = (v) => {
      node.gain.value = v;
    };
    prop.getValue = () => {
      return node.gain.value;
    };
    prop.minValue = 0;
    prop.maxValue = 3;
    prop.defaultValue = node.gain.defaultValue;
    return prop;
  }
  /**
    * 
    * @param {StackLayout} layout 
    */
  collectEditableProperties(layout) {
    let prop = WebAudio.createEditablePropertyForGainNode(this.volumeNode, "Volume");
    layout.addAsTableRow(prop.getEditorElements());

    WebAudio.layerNames.forEach((element) => {
      var found = this.layers.get(element);
      if (found) {
        let prop = WebAudio.createEditablePropertyForGainNode(found, element + " Volume");
        layout.addAsTableRow(prop.getEditorElements());
      }
    });
  }
  /**
   * 
   * @returns {string}
   */
  getAuthorInterfaceName() {
    return "audio";
  }
  /**
   * 
   * @returns {HTMLElement|undefined}
   */
  createAuthorInterfaceElement() {
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    return layout.element;
  }
  /**
   * 
   * @param {Treeview} treeview 
   */
  addAuthorInterfaceElementToTreeview(treeview) {
    let elm = this.createAuthorInterfaceElement();
    treeview.addItem(this.getAuthorInterfaceName(), elm, true);
  }
}
