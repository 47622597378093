//@ts-check
import { ReadonlyProperty } from "./view/propertyEditor";
import { StackLayout } from "./view/stackLayout";
import { AuthorInterface } from "./sceneAuthorInterface/authorInterface";
import { MemoryDiagnostics } from './MemoryDiagnostics';
import { WebApplicationSettings } from './WebApplicationSettings';
import { Treeview } from './view/treeview.js';
import { WebApplicationState } from './WebApplicationState.js';

/**
 * 
 */
export class WebApplication {
  /**
   *
   * @static
   * @type {string}
   */
  static isAudioVisualScriptsEnabled_SettingName = "audio visual scripts enabled";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isAudioVisualScriptsEnabled_SettingDefaultValue = true;
  /**
   *
   * @static
   * @type {string}
   */
  static isPreloadConnectedSceneGraphContentEnabled_SettingName = "Preload Connected SceneGraph Content";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isPreloadConnectedSceneGraphContentEnabled_SettingDefaultValue = false;
  /**
   *
   * @static
   * @type {string}
   */
  static isVideoPlayingTimeoutSettingName = "video playing timeout";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isVideoPlayingTimeoutSettingDefaultValue = false;
  /**
   *
   * @static
   * @type {string}
   */
  static isShowMemoryDiagnosticsOverlaySettingName = "Show Memory Diagnostics Overlay";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isShowMemoryDiagnosticsOverlaySettingDefaultValue = false;
  //static isMemoryDiagnosticsOverlayTimeoutSettingName = "Memory Diagnostics Overlay Inactivity Timeout Minutes";
  //static isMemoryDiagnosticsOverlayTimeoutSettingDefaultValue=5;
  /**
   *
   * @static
   * @type {string}
   */
  static isShowConsoleLogOverlaySettingName = "Show Console Log Overlay";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isShowConsoleLogOverlaySettingDefaultValue = true;
  /**
  *
  * @static
  * @type {string}
  */
  static isConsoleLogOverlayTimeoutSettingName = "Console Log Overlay Inactivity Timeout Minutes";
  /**
   *
   * @static
   * @type {number}
   */
  static isConsoleLogOverlayTimeoutSettingDefaultValue = 5;
  /**
   *
   * @static
   * @type {string}
   */
  static isShowDebugOverlaySettingName = "Show Debug Overlay";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isShowDebugOverlaySettingDefaultValue = true;
  /**
   *
   * @static
   * @type {string}
   */
  static isShowDetailedDebugOverlaySettingName = "Show Detailed Debug Overlay";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isShowDetailedDebugOverlaySettingDefaultValue = false;
  /**
   *
   * @static
   * @type {string}
   */
  static isDebugOverlayTimeoutSettingName = "Debug Overlay Inactivity Timeout Minutes";
  /**
   *
   * @static
   * @type {number}
   */
  static isDebugOverlayTimeoutSettingDefaultValue = 0.5;
  /**
   *
   * @static
   * @type {string}
   */
  static isLoadApplicationLocalStorageSettingName = "isLoadApplicationLocalStorage";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isLoadApplicationLocalStorageSettingDefaultValue = false;
  /**
   *
   * @static
   * @type {string}
   */
  static isSaveApplicationLocalStorageSettingName = "isSaveApplicationLocalStorage";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isSaveApplicationLocalStorageSettingDefaultValue = false;
  /**
   *
   * @static
   * @type {string}
   */
  static isDeleteBeforeSaveApplicationLocalStorageSettingName = "isDeleteBeforeSaveApplicationLocalStorage";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isDeleteBeforeSaveApplicationLocalStorageSettingDefaultValue = false;

  /**
   *
   * @static
   * @type {string}
   */
  static isLoadAccountLocalStorageSettingName = "isLoadAccountLocalStorage";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isLoadAccountLocalStorageSettingDefaultValue = false;
  /**
  *
  * @static
  * @type {string}
  */
  static isSaveAccountLocalStorageSettingName = "isSaveAccountLocalStorage";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isSaveAccountLocalStorageSettingDefaultValue = false;
  /**
  *
  * @static
  * @type {string}
  */
  static isPreCacheAssetsSettingName = "isPreCacheAssets";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isPreCacheAssetsSettingDefaultValue = false;
  /**
  *
  * @static
  * @type {string}
  */
  static isUseRESTSettingName = "isUseREST";
  /**
   *
   * @static
   * @type {boolean}
   */
  static isUseRESTSettingDefaultValue = false;
  /**
   *
   * @static
   * @type {string}
   */
  static IsVerboseLoggingSettingName = "verbose logging";
  /**
   *
   * @static
   * @type {boolean}
   */
  static IsVerboseLoggingSettingDefaultValue = false;
  /**
   *
   * @static
   * @type {string}
   */
  static IsVideoEnabledSettingName = "video enabled";
  /**
   *
   * @static
   * @type {boolean}
   */
  static IsVideoEnabledSettingDefaultValue = true;

  /**
   * @static
   * @type {string}
   */
  static IsMSVideoEnabledSettingName = "MediaSource video enabled";
  /**
   * @static
   * @type {boolean}
   */
  static IsMSVideoEnabledSettingDefaultValue = true;
  /**
   *
   * @static
   * @type {string}
   */
  static IsDBVideoEnabledSettingName = "double buffered video enabled";
  /**
   *
   * @static
   * @type {boolean}
   */
  static IsDBVideoEnabledSettingDefaultValue = false;
  /**
   *
   * @static
   * @type {string}
   */
  static IsSBVideoEnabledSettingName = "single buffered video enabled";
  /**
   *
   * @static
   * @type {boolean}
   */
  static IsSBVideoEnabledSettingDefaultValue = false;
  /**
   *
   * @static
   * @type {string}
   */
  static IsPSVideoEnabledSettingName = "per-scene video enabled";
  /**
   *
   * @static
   * @type {boolean}
   */
  static IsPSVideoEnabledSettingDefaultValue = false;
  /**
   *
   * @static
   * @type {string}
   */
  static IsAllCanvasVideosSettingName = "per-scene canvas video enabled";
  /**
   *
   * @static
   * @type {boolean}
   */
  static IsAllCanvasVideosSettingDefaultValue = false;
  /**
   *
   * @static
   * @type {string}
   */
  static VideoFramesPerSecondSettingName = "per-scene canvas video Frames Per Second";
  /**
   *
   * @static
   * @type {number}
   */
  static VideoFramesPerSecondSettingDefaultValue = 30;
  /**
   *
   * @static
   * @type {string}
   */
  static IsAuthEnabledSettingName = "auth enabled";
  /**
   *
   * @static
   * @type {boolean}
   */
  static IsAuthEnabledSettingDefaultValue = true;
  /**
   *
   * @static
   * @type {string}
   */
  static IsAudioEnabledSettingName = "audio enabled";
  /**
   *
   * @static
   * @type {boolean}
   */
  static IsAudioEnabledSettingDefaultValue = true;
  /**
   *
   * @static
   * @type {string}
   */
  static IsWebpEnabledSettingName = "WebP enabled";
  /**
   *
   * @static
   * @type {boolean}
   */
  static IsWebpEnabledSettingDefaultValue = true;
  /**
   *
   * @static
   * @type {string}
   */
  static IsWebpWebAssemblyEnabledSettingName = "WebP web assembly enabled";
  /**
   *
   * @static
   * @type {boolean}
   */
  static IsWebpWebAssemblyEnabledSettingDefaultValue = false;

  /**
   * 
   * @type {import('./WebApplicationSettings').WebApplicationSettings}
   */
  settings;
  /**
   * 
   * @type {import('./resources').Resources}
   */
  resources;
  /**
   * 
   * @type {string}
   */
  name;
  /**
   * 
   * @type {object}
   */
  json;
  /**
   * 
   * @type {string|undefined}
   */
  versionNumber;
  /**
   * 
   * @type {import('./webApplicationServer').WebApplicationServer}
   */
  server;
  /**
   * 
   * @type {import('./MemoryDiagnostics').MemoryDiagnostics}
   */
  memory_diagnostics;
  /**
   * 
   * @type {import('./audio_visual/InteractivePlatformCanvas').InteractivePlatformCanvas}
   */
  platform_canvas;

  /**
   * 
   * @param {import('./resources.js').Resources} resources 
   * @param {string} name 
   * @param {string|undefined} versionNumber 
   */
  constructor(resources, name = "", versionNumber = undefined) {
    this.resources = resources;
    this.name = name;
    this.versionNumber = versionNumber;
    this.memory_diagnostics = new MemoryDiagnostics(this);
  }

  /**
   * 
   * @param {import('./webApplicationServer').WebApplicationServer} server 
   */
  setServer(server) {
    this.server = server;
  }

  /**
   * 
   * @returns {string}
   */
  stroageItemName() {
    return this.resources.combineJsonResourceName(this.name, "application.storage");
  }

  /**
   * 
   */
  initialize() {
    this.settings = new WebApplicationSettings();
    this.settings.initialize();
    this.settings.addSettings();

    if (this.getSetting(WebApplication.isLoadApplicationLocalStorageSettingName)) {
      this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName(), false);
      this.settings.loadFromStorage(this.json);
    } else {
      this.json = {};
    }
    //this.saveState();
  }

  /**
   * 
   * @param {string} name 
   * @param {import('./ValueChangedSubscriptions').onValueChanged} onChanged 
   */
  addSettingSubscription(name, onChanged) {
    this.settings.addSettingSubscription(name, onChanged);
  }
  /**
   * 
   * @param {string} name 
   * @param {import('./ValueChangedSubscriptions').onValueChanged|undefined} onChanged 
   */
  removeSettingSubscription(name, onChanged) {
    if(!onChanged){
      return;
    }
    this.settings.removeSettingSubscription(name, onChanged);
  }
  /**
   * 
   * @param {string} property 
   * @param {*} v 
   * @param {boolean} isNotify 
   */
  setSetting(property, v, isNotify = true) {
    this.settings.setSetting(property, v, isNotify);
  }
  /**
   * 
   * @param {string} property 
   * @param {*} v 
   */
  setDefaultSetting(property, v) {
    this.settings.setDefaultSetting(property, v);
  }
  /**
   * 
   * @param {string} name 
   */
  toggleBooleanSetting(name) {
    this.settings.toggleBooleanSetting(name);
  }
  /**
   * 
   * @param {string} name 
   * @returns {*}
   */
  getSetting(name) {
    return this.settings.getSetting(name);
  }

  /**
   * 
   * @returns {object}
   */
  get settings_json() {
    return this.settings.json.settings;
  }
  /**
   * 
   */
  saveState() {

    if (this.getSetting(WebApplication.isDeleteBeforeSaveApplicationLocalStorageSettingName)) {
      this.resources.deleteLocalStorageItem(this.stroageItemName());
    }

    if (this.getSetting(WebApplication.isSaveApplicationLocalStorageSettingName)) {
      let json_copy = Object.assign({}, this.json, {});
      this.settings.saveToStorage(json_copy);
      this.resources.setLocalStorageItemAsJson(this.stroageItemName(), json_copy);
    }
  }
  /**
   * 
   */
  shutdown() {
    this.saveState();
  }
  /**
   * 
   * @param {StackLayout} layout 
   */
  collectEditableProperties(layout) {
    let version_property = Object.assign(new ReadonlyProperty(), {
      name: "version",
      getValue: () => {
        return this.versionNumber;
      },
    });
    layout.addAsTableRow(version_property.getEditorElements());

    layout.addAsTableRow(
      Object.assign(new ReadonlyProperty(), {
        name: "url",
        getValue: () => {
          return window.location.href;
        },
      }).getEditorElements()
    );
  }
  /**
   * 
   * @returns {string}
   */
  getAuthorInterfaceName() {
    return this.name;
  }

  /**
   * 
   * @returns {HTMLElement}
   */
  createAuthorInterfaceElement() {
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    AuthorInterface.collectEditablePropertiesFromProperties(this.settings.json.settings, this.settings.json.ui.settings, this.settings.json.default.settings, layout, this.settings.setting_subscriptions);
    return layout.element;
  }

  /**
   * 
   * @param {Treeview} treeview 
   */
  addAuthorInterfaceElementToTreeview(treeview) {
    let elm = this.createAuthorInterfaceElement();
    treeview.addItem(this.getAuthorInterfaceName(), elm, true);
  }
  /**
   * 
   * @param {WebApplicationState} state 
   */
  initializeExternalModules(state) {

  }
  /**
   * 
   * @param {WebApplicationState} state 
   */
  initializeInternalModules(state) {

  }
  /**
   * 
   * @param {WebApplicationState} state 
   */
  initializeSceneModules(state) {

  }
}
