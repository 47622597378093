import { Stem } from '../stem.js';

/**
 * @interface
 */
export default class WebAudio {
    /**
     * @type {string}
     */
    static ambiancelayerName = "ambiance";
    /**
     * @type {string}
     */
    static musiclayerName = "music";
    /**
     * @type {string}
     */
    static effectslayerName = "effects";
    /**
     * @type {Array.<string>}
     */
    static layerNames = [WebAudio.ambiancelayerName, WebAudio.musiclayerName, WebAudio.effectslayerName];
    /**
     * @type {import('./ConnectedStems.js').ConnectedStems}
     */
    connectedStems;
    /**
     * @type {Map.<string,GainNode>}
     */
    layers = new Map();
    /** 
     * stores a reference to the playlist manager object
     * @type {PlaylistManager} 
     */
    playlistManager

    /**
     * @type {AudioContext}
     */
    audioContext

    /**
     * 
     * @param {GainNode} node 
     * @param {string} name 
     * @returns {NumberEditableProperty} 
     */
    static createEditablePropertyForGainNode(node, name) { }

    /**
     * 
     * @param {import('../../resources.js').Resources} resources 
     * @param {import('../../customerAccount.js').CustomerAccount} account 
     */
    constructor(resources, account) {
        this.resources = resources;
        this.account = account;

        this.connectedStems = new ConnectedStems(this);
        // this.playlistManager = new PlaylistManager("playlists", getRadioSettings)
    }
    /**
     * 
     * @param {string} layer 
     * @param {GainNode} node 
     */
    connectNodeToLayer(layer, node) {
        let layerNode = this.layers.get(layer);
        if (layerNode) {
            node.connect(layerNode);
        }
        else {
            node.connect(this.volumeNode);
        }
    }
    /**
     * Initializes Audio audio Player
     */
    initialize() { }
    /**
     * Starts Audio
     * @returns {boolean} true on success false on failure
     */
    tryStartAudio() { }
    /**
     * Uninitializes Audio
     */
    uninitialize() { }
    /**
     * Disables Audio Player
     */
    deactivate() { }
    /**
     * Activates a disabled audio player
     */
    reactivate() { }
    /**
     * @returns {string} the name of "storage item"
     */
    storageItemName() { }
    /**
     * saves the state of the audio player
     */
    saveState() { }
    /**
     * Shuts down audio system, called when application is closed as clean up
     */
    shutdown() { }
    /**
     * Plays all audio stems 
     * @param {Stem[]} stems  - audio stem values
     */
    playSoundEffects(stems) { }
    /**
     * Starts Audio Component when a gesture has been received
     */
    startAudioOnGesture() { }

    /**
     * @param {StackLayout} layout 
     */
    collectEditableProperties(layout) { }
    /**
     * @returns {string}
     */
    getAuthorInterfaceName() { }
    /**
     * @returns {HTMLElement|undefined} 
     */
    createAuthorInterfaceElement() { }
    /**
     * @param {Treeview} treeview 
     */
    addAuthorInterfaceElementToTreeview(treeview) { }
}