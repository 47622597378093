import { AudioVisualScriptResourceCanvasElement } from '../../audio_visual/visual/resourceCanvasElements/AudioVisualScriptResourceCanvasElement';
import { Action } from '../actions/action';

/**
 *
 */
export class NextAudioVisualScriptPlaybackQueueItem extends Action {

  /**
   *
   * @param {string} verb
   * @param {string} name
   * @param {Object} json
   */
  constructor(verb, name, json) {
    super(verb, name);
  }

  /**
   *
   * @param {import('../scene').SceneObjectInterface} interactive
   */
  run(interactive) {
    console.log("action:NextAudioVisualScriptPlaybackQueueItem");
    AudioVisualScriptResourceCanvasElement.nextPlaybackQueueItem(interactive.scene);
  }
}
