// @ts-check
import { SingleBufferedSharedVideoResourceCanvasElement } from '../resourceCanvasElements/SingleBufferedSharedVideoResourceCanvasElement.js';
import { FileResourceRequest } from '../../../resources/FileResourceRequest.js';

import { FileResource } from '../../../resources/FileResource.js';
import { VisualElement } from '../visualElement.js';
import { MediaSourcePlayerWrapper } from '../resourceCanvasElements/MediaSourceVideoElementController.js';
let c2 = require("c2.js");
const TAG = "MediaSourceResourceResolver:"

export class MediaSourceResourceResolver extends FileResource {

  static requestMp4VideoResource(request) {
    return new MediaSourceResourceResolver(request);
  }
  static disposeMp4VideoResource(resource) { }

  static canCreateResourceFromJson(json, property) {
    return property == "video" && json[property] != undefined;

  }
  static canCreateResourceFromJsonObject(json) {
    return json["video"] != undefined;
  }
  static requestResource(name, path, path2, webapp) {
    let init = name
    if (name.endsWith("-4k")) {
      init = name.substring(0, name.length - 2) + "init"
    } else {
      init = init + "-init"
    }

    let request = new FileResourceRequest(path, init, "", "", path2, true, false, false, webapp);

    request.setExtension(".mp4");
    request.setCategoryPath("visuals/video/");

    console.log(request.toURLName())
    var file_entries = webapp.server.server_file_cache.get_additional_manifest_entries_for_file_request(request);
    if (file_entries?.length > 0) {

      var names = file_entries.map(each => FileResource.getFullpathFilenameWithoutExtension(each));
      var selection = webapp.platform_canvas.select_video_content(names);
      if (selection) {
        request.change_to_filename(selection);
      }
    }

    console.log(TAG, "request ", request)
    var resource = this.requestMp4VideoResource(request);

    if (resource) {
      resource.url = resource.resource_request.toUrlPath();
      resource.server_file_cache = webapp.server.server_file_cache;
      resource.url_file_info = resource.server_file_cache.server_asset_lookup[resource.url];
      resource.type = "video";
    }

    return resource;
  }
  static createResourceFromJson(json, property, path, path2, webapp) {
    if (this.canCreateResourceFromJson(json, property)) {
      let result = this.requestResource(json.video, path, path2, webapp);
      result.event_playedOnce = json["video.event.playedOnce"];

      return result;
    }
  }


  started_element_error_callback;
  type
  /** @type {HTMLVideoElement} */
  resource_element;
  resource_request;
  /** @type {boolean} */
  isError;
  /** @type {boolean} */
  isLoaded = false;
  url_file_info;
  event_playedOnce;
  onRunAction;
  server_file_cache;

  /** @type {ArrayBuffer} */
  rawData

  download_diagnostics;

  constructor(resource_request) {
    super()
    this.resource_request = resource_request;
  }

  toSourceURL() {
    return this.resource_request.toUrlPath();
  }

  toSourceURLName() {
    return this.resource_request.name;
  }

  /** 
   * @param {VisualElement} visualElement 
   * @returns {MediaSourcePlayerWrapper}
   */
  createResourceCanvasElement(visualElement) {
    return new MediaSourcePlayerWrapper (visualElement, this);
  }

  unapply_property_values_to_resource_element = () => {}
  unapply_event_values_to_resource_element = () => {}
  cancelLoading = () => {}
  apply_property_values_to_resource_element = () => {}

  on_buffer_ended(index, buffer) {
    if (this.event_playedOnce != undefined) {
      this.onRunAction?.(this.event_playedOnce);
    }
  }

  start() {

  }
  // /** @param {HTMLVideoElement} video_element */
  // start(video_element) {
  //   console.log(TAG, " start ", video_element)

  //   this.resource_element = video_element;
  //   this.player = new MediaSourcePlayer(this.resource_element)
  //   let url = this.resource_request.toUrlPath();

    
  //   this.fetchResource(url)
  //     .then(response => {
  //       this.rawData = response
  //       this.isLoaded = true
  //     })
  //     .catch(err => {
  //       this.notifyError()
  //     })


  // }

  notifyError() {
    this.isError = true;
  }
  stop() { }

  isLoading() {
    return false;
  }

  checkForErrors() {
    return false
  }

  createCanvasElement(resource_canvas_element) {
    return undefined
  }

  pixel_size() {
    var video_width = this.url_file_info.width;
    var video_height = this.url_file_info.height
    return [video_width, video_height];
  }

  async fetchResource(url) {
    const request = new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          xhr.responseType = 'arraybuffer';
          xhr.send();
          xhr.onload = function (e) {
              if (xhr.status != 200) {
                  // onLoad();
                  reject(new Error(`Failed to download file: ${xhr.status}`));
              } 
              resolve(xhr.response);
          };
          xhr.onerror = (e) => {
              // vid.src = null;
              reject(new Error(`Failed to download file: ${xhr.status}`));
          };
      })
    return await request
  }

}
