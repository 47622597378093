// @ts-check
/**
 * @callback actionDelegate
*/

/**
 * Video Data
 */
export class VideoData {

    /** @type {ArrayBuffer} */
    video;
    /** @type {string|undefined} */
    name;
    /** @type {object|undefined} */
    event_playedOnce;
    /** @type {boolean} */
    isLoop = true;
    /** @type {boolean} */
    isMuted = false;
    /** @type {boolean} */
    isAutoPlay = true;
    /** @type {boolean} */
    isPlayImmediate = true;

    /** @type {actionDelegate|undefined} */
    onPlayedOnce;

    /**
     *
     * @param {object} json
     * @returns {VideoData}
     */
    static newFromJson(json) {
        var result = new VideoData();
        result.event_playedOnce = json["video.event.playedOnce"];
        if (json["video.loop"] != undefined) {
            result.isLoop = json["video.loop"];
        }
        if (json["video.muted"] != undefined) {
            result.isMuted = json["video.muted"];
        }
        if (json["video.autoplay"] != undefined) {
            result.isAutoPlay = json["video.autoplay"];
        }
        if (json["video.play_immediate"] != undefined) {
            result.isPlayImmediate = json["video.play_immediate"];
        }
        return result;
    }

    /**
     *
     * @returns {VideoData}
     */
    clone() {
        return Object.create(this);
    }
}
