// @ts-check

import { FileResourceRequest } from '../../../resources/FileResourceRequest';
import { fileDownload } from './util';

/**
 * 
 */
export class AudioResource {
  /** @type {ArrayBuffer} */
  resource_element;
  /** @type {FileResourceRequest} */
  resource_request;
  /** @type {Promise|undefined} */
  loading_promise;
  /** @type {Array.<Promise|undefined>} */
  loading_promises = [];
  /**
   * 
   * @returns {boolean}
   */
  checkForErrors() {
    return false;
  }
  /**
   * 
   * @returns {string}
   */
  toSourceURLName() {
    return this.resource_request.name;
  }
  /**
   * 
   * @param {FileResourceRequest} resource_request 
   */
  constructor(resource_request) {
    this.resource_request = resource_request;

    this.loading_promise = fileDownload(this.resource_request.toUrlPath())

    this.loading_promise.then((buffer) => {
      this.resource_element = buffer
    })

    this.loading_promises.push(this.loading_promise);
  }
  /**
   * 
   * @param {import('../../../sceneGraph/scene').SceneObjectInterface} [scene] 
   */
  initialize(scene) {

  }
  /**
   * 
   */
  dispose() {
    var isLoading = this.isLoading();
    if (isLoading) {
      var self = this;
      var and_then = (res) => {
        self.dispose();
      };
      this.addLoadingThen(and_then);
    } else {

      console.log(`audio:resource:close AudioResource ${this.resource_request.toUrlPath()}`);
    }
  }
  /**
   * 
   * @param {*} and_then 
   */
  addLoadingThen(and_then) {
    var latest = this.getLoadingPromise();

    if (!latest) {
      latest = new Promise((resolve, reject) => {
        resolve(true);
      });
    }

    var index;
    index = this.loading_promises.length;
    var next = latest.then(and_then);
    next = next.catch((e) => {
      console.error(e);
    });
    next = next.finally(() => {
      this.loading_promises[index] = undefined;
    });

    this.loading_promises.push(next);
  }
  /**
   * 
   * @returns {boolean}
   */
  isLoading() {
    return this.getLoadingPromise() !== undefined;
  }
  /**
   * 
   * @returns {Promise|undefined}
   */
  getLoadingPromise() {
    return this.loading_promises.length == 0 ? undefined : this.loading_promises[this.loading_promises.length - 1];
  }

}
