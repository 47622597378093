// @ts-check

/* context

https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Migrating_from_webkitAudioContext
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Web_audio_spatialization_basics
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Best_practices

https://developer.chrome.com/blog/autoplay/#webaudio

*/

import { NumberEditableProperty } from "../../view/propertyEditor";
import { StackLayout } from "../../view/stackLayout";
import { Treeview } from '../../view/treeview.js';
import { ConnectedStems } from "./ConnectedStems";
/**
 * @callback actionFunction
*/
/**
 * @callback initialize
*/
/**
 * @callback saveState
*/
/**
 * @callback connectNodeToLayer_function
 * @param {string} layer 
* @param {GainNode} node 
*/
/**
 * @typedef WebAudioInterface
 * @property {import('../../resources.js').Resources} resources
 * @property {initialize} initialize
 * @property {saveState} saveState
 * @property {AudioContext} audioContext
 * @property {import('./ConnectedStems.js').ConnectedStems} connectedStems
 * @property {connectNodeToLayer_function} connectNodeToLayer
 * @property {import('../interactive_canvas').start_InteractiveCanvasComponentInterfaceFunction} start 
 * @property {import('../interactive_canvas').onActivity_InteractiveCanvasComponentInterfaceFunction} [onActivity] 
 * @property {import('../interactive_canvas').activate_InteractiveCanvasComponentInterfaceFunction|undefined} [activate] 
 * @property {import('../interactive_canvas').drawFrame_InteractiveCanvasComponentInterfaceFunction} drawFrame 
 * @property {import('../interactive_canvas').mousedown_InteractiveCanvasComponentInterfaceFunction} mousedown 
 * @property {import('../interactive_canvas').keydown_InteractiveCanvasComponentInterfaceFunction} keydown 
 * @property {import('../interactive_canvas').keyup_InteractiveCanvasComponentInterfaceFunction} [keyup] 
 * @property {import('../interactive_canvas').mouseup_InteractiveCanvasComponentInterfaceFunction} mouseup 
 * @property {import('../interactive_canvas').mousemove_InteractiveCanvasComponentInterfaceFunction} mousemove 
 * @property {import('../interactive_canvas').file_dropped_InteractiveCanvasComponentInterfaceFunction|undefined} [file_dropped] 
 * @property {import('../interactive_canvas').drag_file_InteractiveCanvasComponentInterfaceFunction|undefined} [drag_file]
 * @property {import('../../sceneAuthorInterface/authorInterface').AuthorInterfaceComponentInterface_addAuthorInterfaceElementToTreeview} [addAuthorInterfaceElementToTreeview] 
 * @property {import('../../sceneAuthorInterface/authorInterface').AuthorInterfaceComponentInterface_createAuthorInterfaceElement} [createAuthorInterfaceElement] 
 * @property {import('../../sceneAuthorInterface/authorInterface').AuthorInterfaceComponentInterface_getAuthorInterfaceName} getAuthorInterfaceName 

 */

/**
 * 
 */
export class WebAudio {
  /**
   * @type {AudioContext}
   */
  audioContext;
  /**
* 
* @type {import('../../customerAccount.js').CustomerAccount}
*/
  account;
  /**
* 
* @type {import('../../resources.js').Resources}
*/
  resources;
  /**
 * @type {boolean}
 */
  isWaitingForGesture;
  /**
 * @type {import('./ConnectedStems.js').ConnectedStems}
 */
  connectedStems;
  /**
* @type {string}
*/
  static ambiancelayerName = "ambiance";
  /**
* @type {string}
*/
  static musiclayerName = "music";
  /**
* @type {string}
*/
  static effectslayerName = "effects";
  /**
* @type {Array.<string>}
*/
  static layerNames = [WebAudio.ambiancelayerName, WebAudio.musiclayerName, WebAudio.effectslayerName];
  /**
 * @type {Map.<string,GainNode>}
 */
  layers = new Map();
  /**
* @type {GainNode}
*/
  volumeNode;

  /*
* @type {number}
*/
  //archiveVolume;
  /**
   * @type {actionFunction}
   */
  onStartAudioOnGesture;

  /**
   * 
   * @param {import('../../resources.js').Resources} resources 
   * @param {import('../../customerAccount.js').CustomerAccount} account 
   */
  constructor(resources, account) {
    this.resources = resources;
    this.account = account;

    this.connectedStems = new ConnectedStems(this);
  }
  /**
   * 
   * @param {string} layer 
   * @param {GainNode} node 
   */
  connectNodeToLayer(layer, node) {
    let layerNode = this.layers.get(layer);
    if (layerNode) {
      node.connect(layerNode);
    }
    else {
      node.connect(this.volumeNode);
    }
  }
  /**
   * 
   */
  initialize() {
    this.tryStartAudio();
  }
  /**
   * 
   * @returns {boolean}
   */
  tryStartAudio() {
    if (this.audioContext != undefined) {
      return true;
    }
    try {
      this.audioContext = new AudioContext();
      this.isWaitingForGesture = this.audioContext.state === "suspended";

      this.volumeNode = this.audioContext.createGain();
      this.volumeNode.connect(this.audioContext.destination);

      WebAudio.layerNames.forEach((element) => {
        this.layers.set(element, this.audioContext.createGain());
        this.layers.get(element)?.connect(this.volumeNode);
      });
      return true;
    } catch (e) { }
    return false;
  }
  /**
   * 
   */
  uninitialize() { }
  /**
   * 
   */
  start() { }
  /**
   * 
   */
  deactivate() {
    this.isDeactivate = true;
    this.audioContext.suspend();
    //this.archiveVolume=this.volumeNode.gain.value;
    // this.volumeNode.gain.value=0;
    //this.volumeNode.disconnect();
  }
  /**
   * 
   */
  reactivate() {
    if (this.isDeactivate) {
      this.isDeactivate = false;

      this.audioContext.resume();
      // this.volumeNode.gain.value=this.archiveVolume;
      //this.volumeNode.connect(this.audioContext.destination);
    }
  }
  /**
   * 
   * @returns {string}
   */
  stroageItemName() {
    return this.resources.combineJsonResourceName(this.account.application.name, this.account.name, "audio.storage");
  }
  /**
   * 
   */
  saveState() {
    //this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }
  /**
   * 
   */
  shutdown() {
    this.saveState();
  }
  /**
   * 
   * @param {Array.<import('./stem.js').Stem>} stems 
   */
  playSoundEffects(stems) {
    stems.forEach(element => {
      this.connectedStems.playSoundEffect(element);
    });
  }
  /**
   * 
   */
  startAudioOnGesture() {
    this.tryStartAudio();

    if (this.isWaitingForGesture) {
      this.audioContext.resume();
      this.isWaitingForGesture = false;
      if (this.onStartAudioOnGesture) {
        this.onStartAudioOnGesture();
      }

      console.log("AudioContext resume");
      this.connectedStems.startAudioOnGesture();

    }
  }

  /**
* 
* @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas 
*/
  drawFrame(icanvas) { }
  /**
* 
* @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas 
* @param {import('../../MouseEvent.js').InteractiveMouseEvent} e 
*/
  mousedown(icanvas, e) {
    this.startAudioOnGesture();
  }
  /**
* 
* @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas 
* @param {import('../../MouseEvent.js').InteractiveMouseEvent} e 
*/
  mouseup(icanvas, e) { }
  /**
* 
* @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas 
* @param {import('../../MouseEvent.js').InteractiveMouseEvent} e 
*/
  mousemove(icanvas, e) { }
  /**
  * 
  * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas 
  * @param {import('../../sceneGraph/InteractiveEvent.js').InteractiveEvent} ievent 
  */
  keydown(icanvas, ievent) {
    this.startAudioOnGesture();
  }
  /**
* 
* @param {*} e 
*/
  onTouchTap(e) {
    this.startAudioOnGesture();
  }
  /**
* 
* @param {*} e 
*/
  onTouchPan(e) { }
  /**
* 
* @param {*} e 
*/
  onTouchSwipe(e) {
    this.startAudioOnGesture();
  }
  /**
* 
* @param {*} e 
*/
  onTouchDistance(e) { }
  /**
* 
* @param {*} e 
*/
  onTouchRotate(e) { }
  /**
* 
* @param {*} e 
*/
  onTouchGesture(e) { }
  /**
   * 
   * @param {GainNode} node 
   * @param {string} name 
   * @returns {NumberEditableProperty}
   */
  static createEditablePropertyForGainNode(node, name) {
    let prop = new NumberEditableProperty();
    prop.name = name;
    prop.setValue = (v) => {
      node.gain.value = v;
    };
    prop.getValue = () => {
      return node.gain.value;
    };
    prop.minValue = 0;
    prop.maxValue = 3;
    prop.defaultValue = node.gain.defaultValue;
    return prop;
  }
  /**
    * 
    * @param {StackLayout} layout 
    */
  collectEditableProperties(layout) {
    let prop = WebAudio.createEditablePropertyForGainNode(this.volumeNode, "Volume");
    layout.addAsTableRow(prop.getEditorElements());

    WebAudio.layerNames.forEach((element) => {
      var found = this.layers.get(element);
      if (found) {
        let prop = WebAudio.createEditablePropertyForGainNode(found, element + " Volume");
        layout.addAsTableRow(prop.getEditorElements());
      }
    });
  }
  /**
   * 
   * @returns {string}
   */
  getAuthorInterfaceName() {
    return "audio";
  }
  /**
* 
* @returns {HTMLElement|undefined}
*/
  createAuthorInterfaceElement() {
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    return layout.element;
  }
  /**
   * 
   * @param {Treeview} treeview 
   */
  addAuthorInterfaceElementToTreeview(treeview) {
    let elm = this.createAuthorInterfaceElement();
    treeview.addItem(this.getAuthorInterfaceName(), elm, true);
  }
}
