
//@ts-check
import { FileResourceRequest } from "./resources/FileResourceRequest.js";
import { AudioResource } from "./audio_visual/audio/resources/audioResource.js";

/**
 * @callback disposeFunction 
*/

/**
 * @typedef ResourceInterface
 * @property {disposeFunction} dispose 
*/

/**
 * 
 */
export class Resources {
  /**
   * @type {import('./webApplicationServer.js').WebApplicationServer}
   */
  server;
  /**
   * @type {object}
   */
  fetched_json = {};
  /**
   * @type {string}
   */
  static JsonExtension = ".json";
  /**
   * @returns {Promise}
   */
  async Initialize() {

    var scene_graphs_promise = this.getOrFetchJsonPromise(this.getNavigationGraphPathFromName(""));

    scene_graphs_promise = scene_graphs_promise.then(async (result) => {

      let fetchSceneGraphs;

      result.navigationGraphs?.forEach((element) => {
        var path = this.getNavigationGraphPathFromName(element);

        if (fetchSceneGraphs === undefined) {
          fetchSceneGraphs = [];
        }

        fetchSceneGraphs.push(this.getOrFetchJsonPromise(path));
      });

      if (fetchSceneGraphs) {
        await Promise.all(fetchSceneGraphs);
      }
    });

    var file_manifest_promise = this.getOrFetchJsonPromise(this.getFileManifestJsonPath());

    await Promise.all([scene_graphs_promise, file_manifest_promise]);
  }
  /**
   * 
   * @param {string} name 
   * @param {string} ext 
   * @returns {string}
   */
  getDataFilePathByNameAndExtension(name, ext) {
    return `./assets/data/${name}${ext}`;
  }
  /**
   * 
   * @param {string} name 
   * @returns {Promise.<object>}
   */
  async getOrFetchSceneGraphJson(name) {
    var path = this.getNavigationGraphPathFromName(name);

    return await this.getOrFetchJson(path);
  }
  /**
   * 
   * @param {string} path 
   * @returns {Promise.<object>}
   */
  getOrFetchJsonPromise(path) {

    var found = this.fetched_json[path];

    if (!found) {
      var p = this.getJsonPromise(path);
      p = p.then((result) => {

        if (result == 404) {
          console.error(`resources:404 error ${path}`);
        }
        this.fetched_json[path] = result;
        return result;
      });
      return p;
    }

    return Promise.resolve(found);
  }
  /**
   * 
   * @param {string} path 
   * @returns {boolean}
   */
  isJsonFetched(path) {
    return this.fetched_json[path] !== undefined;
  }
  /**
   * 
   * @param {string} path 
   * @returns {object}
   */
  getFetchedJson(path) {
    return this.fetched_json[path];
  }
  /**
   * 
   * @param {string} path 
   * @returns {Promise.<object>}
   */
  async getOrFetchJson(path) {

    var found = this.fetched_json[path];

    if (!found) {
      found = await this.getJsonAsync(path);

      if (found == 404) {
        console.error(`resources:404 error ${path}`);
      }
      this.fetched_json[path] = found;
    }
    return found;
  }
  /**
   * 
   * @param {string} name 
   * @returns {string}
   */
  getNavigationGraphPathFromName(name) {
    var filename = name;
    if (filename) {
      filename += ".";
    }
    filename += "navigation.graph";
    var path = this.getJsonPath(filename);
    return path;
  }
  /**
   * 
   * @returns {string}
   */
  getFileManifestJsonPath() {
    return this.getJsonPath("file_manifest");
  }
  /**
   * 
   * @param {string} name 
   * @returns {string}
   */
  getJsonPath(name) {
    return `./assets/data/${name}.json`;
  }
  /**
   * 
   * @param {string} path 
   * @returns {Promise}
   */
  getJsonPromise(path) {
    console.log(`resources:fetch ${path}`);
    var p = fetch(path);

    p = p.then(async (result) => {
      return await result.json();
    });

    return p;
  }

  async getJsonAsync(path) {
    console.log(`resources:fetch ${path}`);
    const r = await fetch(path);
    const j = await r.json();

    return j;
  }
  /**
   * 
   * @param {Array.<string>} args 
   * @param {boolean} append_extension 
   * @returns {string}
   */
  combineJsonResourceNames(args, append_extension = true) {
    let result = "";
    for (let each in args) {
      let each_value = args[each];
      if (each_value) {
        result += each_value;
        result += ".";
      }
    }
    if (append_extension) {
      result += "json";
    } else {
      result = result.slice(0, -1);
    }

    return result;
  }
  /**
   * 
   * @returns {string}
   */
  combineJsonResourceName() {
    let result = "";
    for (let each in arguments) {
      let each_value = arguments[each];
      if (each_value) {
        result += each_value;
        result += ".";
      }
    }

    result += "json";

    return result;
  }
  /**
   * 
   * @param {FileResourceRequest} request 
   * @returns {AudioResource}
   */
  requestMp3AudioResource(request) {
    request.setExtension(".mp3");
    request.setCategoryPath("audio/");
    request.application = this.server.app;
    //let found = this.audios[request.fullpath];

    //if (found) {
    //  return found;
    // }

    request.server_file_cache = this.server.server_file_cache;
    var found = new AudioResource(request);
    //this.audios[request.fullpath] = found;
    return found;
  }

  /**
   * 
   * @param {ResourceInterface|undefined} resource 
   */
  disposeResource(resource) {
    resource?.dispose?.();
  }

  /**
   * 
   * @param {string} name 
   * @param {string} path 
   * @param {string} path2 
   * @returns {AudioResource}
   */
  requestAudioResource(name, path, path2) {
    let request = new FileResourceRequest(path, name, "", "", path2, true, false, false);
    return this.requestMp3AudioResource(request);

    // var r;

    // if (type == "audio") {
    //   r = 
    // }

    // r.type = type;
    // return r;
  }
  /**
   * 
   * @param {string} name 
   * @returns {string|null}
   */
  getLocalStorageItem(name) {
    let found = window.localStorage.getItem(name);
    // if (!found) {
    //   found = ifNotFound;
    // }
    return found;
  }
  /**
   * 
   * @param {string} name 
   * @param {boolean} createIfNotFound 
   * @returns {object}
   */
  getLocalStorageJsonItem(name, createIfNotFound = true) {
    let item = this.getLocalStorageItem(name);

    if (item) {

      if (item === "\"{}\"") {
        item = "{}";
      }

      let json = JSON.parse(item);
      return json;
    }
    else {
      var json_item = {};

      if (createIfNotFound) {
        this.setLocalStorageItemAsJson(name, json_item, true);
        return this.getLocalStorageJsonItem(name, false);
      }
      return json_item;
    }
  }
  /**
   * 
   * @param {string} name 
   */
  deleteLocalStorageItem(name) {
    var item = window.localStorage.getItem(name);

    if (!item) {
      return;
    }

    window.localStorage.removeItem(name);
  }
  /**
   * 
   * @param {string} name 
   * @param {string} value 
   * @param {boolean} createIfNotFound 
   */
  setLocalStorageItem(name, value, createIfNotFound = true) {

    if (!createIfNotFound && window.localStorage.getItem(name) === null) {
      return;
    }
    window.localStorage.setItem(name, value);
  }
  /**
   * 
   * @param {string} name 
   * @param {object} object_value 
   * @param {boolean} createIfNotFound 
   */
  setLocalStorageItemAsJson(name, object_value, createIfNotFound = true) {
    let value = JSON.stringify(object_value);
    this.setLocalStorageItem(name, value, createIfNotFound);
  }
}
