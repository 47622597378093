//@ts-check
import { InteractiveEvent } from "./InteractiveEvent";

/**
 * 
 */
export class DragDropEvent extends InteractiveEvent {
  /**
   * @type {Array.<object>|undefined}
   */
  files;
  /**
    * @type {boolean}
    */
  isDrag;
  /**
   * 
   * @param {import('../audio_visual/interactive_canvas').InteractiveCanvas|undefined} icanvas 
   * @param {DragEvent|undefined} e 
   * @param {Array.<object>|undefined} files 
   */
  constructor(icanvas = undefined, e = undefined, files = undefined) {
    super(icanvas, e);
    this.files = files;
    this.isDrag = false;
  }
}
